.hash-container {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	position: relative;
}

.hash-title {
	margin-bottom: 20px;
}

.hash-input {
	width: 580px;
	overflow-wrap: break-word;
}

.hash-options {
	z-index: 99;
	position: absolute;
	top: 30px;

	background-color: rgb(113, 113, 113);
	color: rgb(255, 255, 255);
	width: 980px;
	max-height: 300px;
	overflow-y: scroll;
	border-radius: 5px 5px 5px 5px;
}

.hash-options-left {
	left: -60px;
}
.hash-options-right {
	left: -300px;
}

.hash-options ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.hash-options ul li {
	position: relative;
	height: 26px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 0 10px;
	font-size: 14px;
}

.hash-options ul li:hover {
	cursor: pointer;
	background-color: rgb(74, 128, 194);
}

.hash-node{
	font-size: 16px;
	width: 630px;
}