.error-container {
	width: 500px;
	text-align: center;
	position: fixed;
	top: 30px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 99;
	padding: 10px 20px;
	background-color: rgb(145, 82, 82);
	border-radius: 5px;
}

.error-error {
	font-weight: bold;
	color: rgb(255, 255, 255);
}

.error-info {
	font-weight: normal;
	color: rgb(0, 0, 0);
}
