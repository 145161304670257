.main-slot-form-container {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
	width: 25%;
	max-width: 500px;
}

.main-slot-form-container section {
	position: relative;
	display: flex;
	align-items: flex-start;
	gap: 50px;
	background-color: rgb(215, 224, 230);
	border-radius: 5px;
	border: 2px solid rgb(195, 203, 208);
	padding: 50px 100px;
}

.main-slot-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.main-slot-input {
	width: 500px;
}

.main-slot-time {
	margin-top: 7px;
	font-size: 16px;
}

.main-on-chain-data{
	font-size: 16px;
}

.main-on-chain-data-mev{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.main-on-chain-data .main-on-chain-data-option{
	margin-top: 7px;
	justify-content: flex-start;
	display: flex;
}

.main-on-chain-data span{
	display: inline-block;
	width: 110px;
}