.builder-container{
    display: flex;
    gap: 20px;
    width: 43vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.builer-options-contianer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.border-2{
    width: 100px;
    height: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.434);
}