table{
    font-size: 16px;
}

.comapre{
    margin: 1em 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    width: 100%;
}

.comapre-table{
    width: 90%;
}

.comapre-head tr th{
    background-color: aliceblue;
    border: 1px solid black;
    padding: 10px 5px;
}

.comapre-body tr td{
    border: 1px solid black;
    padding: 10px 5px;
    overflow-wrap: break-word;
    max-width: 250px;
    text-align: center;
}

.colored-tr{
    background-color: rgb(215, 224, 230);
}