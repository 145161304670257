.main-container{
    position: relative;
    width: 100%;
    height: 100%;
    min-width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 5em 0;
    font-size: 20px;
}

input {
    font-size: 16px;
    height: 24px;
}

.error-section{
    height: 36px;
    width: 100%;
    margin: 1em 0;
}

.main-builder-form-container{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 90%;
    max-width: 1300px;
    margin-top: 2em;
    padding: 50px 100px;
}

.main-builder-slot-selection{
    position: relative;
    display: flex;
    justify-content: space-evenly;
    gap: 20px;
    margin: 20px 0;
}

.main-builder-selection{
    position: relative;
    background-color: rgb(215, 224, 230);
    border-radius: 5px;
    border: 2px solid rgb(195, 203, 208);
    padding: 30px 30px;
    display: flex;
    align-items: flex-start;
    gap: 20px;
    height: 100%;
    min-height: 220px;
}

.main-builder-selection{
    width: 100%;
}

.main-builder-hash{
    width: 100%;
}

.border{
    position: relative;
    top: 30px;
    height: 150px;
    width: 1px;
    margin: 0 10px;
    background-color: rgb(0,0,0,0.5);
}
