select{
    font-size: 16px;
    height: 29.5px;
}

.select-container{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.select-title{

}

.select{
    width: 480px;
}